import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

function ErrorBoundary({ children }: React.PropsWithChildren<{}>) {
  return (
    <ReactErrorBoundary
      FallbackComponent={() => (
        <div className="iv-text-center iv-p-10">
          Something went wrong.
          <div className="iv-text-body-m iv-text-black-50 iv-text-center">
            Contact support on{' '}
            <a href="mailto:support@invideo.io" className="iv-text-blue">
              email
            </a>{' '}
            or{' '}
            <a
              rel="noopener noreferrer"
              href="https://twitter.com/InVideoOfficial"
              className="iv-text-blue"
              target="_blank"
            >
              twitter
            </a>
          </div>
        </div>
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
