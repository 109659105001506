export const localStorageWithExpiry = {
  set: (key: string, value: any, expirationInMinutes = 10) => {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expirationInMinutes * 60 * 1000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  },

  get: (key: string) => {
    const item = localStorage.getItem(key);

    if (!item) return null;

    const parsedItem = JSON.parse(item);

    const now = new Date();

    if (now.getTime() > parsedItem.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return parsedItem.value;
  },

  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};
