module.exports = {
  locales: ['en', 'fr', 'es', 'tr', 'ru', 'pt', 'id', 'it', 'kr', 'jp', 'de'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'shared'],
    '/': ['home'],
    '/homepage': ['home'],
    '/[slug]': ['common'],
    '/preview/[id]': ['home'],
    '/preview/homepage/[id]': ['home'],
    '/preview/homepage-ai/[id]': ['home'],
    '/preview/make/[id]': ['make'],
    '/preview/perf/[id]': ['make'],
    '/make': ['make', 'home'],
    '/make/[id]': ['make', 'home'],
    '/perf/[id]': ['make', 'home'],
    '/ai': ['make', 'home'],
    '/ai/[id]': ['make', 'home'],
    '/pricing': ['make', 'home'],
    '/chrisrecord': ['make', 'home'],
    '/filmr/delete-account': ['make', 'home'],
    '/marismith': ['make', 'home'],
    '/register': ['make', 'home'],
    '/register-partner': ['make', 'home'],
    '/get25': ['make', 'home'],
    '/get25off': ['make', 'home'],
    '/create/[id]': ['make', 'home'],
  },
};
