
import { FeatureConfig } from '@utils/experiments/types';
import { AxiosResponse } from 'axios';
import { UserType } from '@invideoio/web-shared/types/Auth';
import { ApiModule } from '@lib/API';

export async function fetchFeatureJson(): Promise<FeatureConfig | null> {
  const API = await ApiModule();
  const response: AxiosResponse<FeatureConfig> = await API.get(
    String(process.env.NEXT_PUBLIC_FEATURE_JSON_URL),
  );

  if (response.data) {
    return response.data;
  } else {
    return null;
  }
}

export const isOnboardingSurveyPending = (userData: UserType) => {
  const oid = 'persona-survey';
  const onboardings = userData?.onboardings;
  if (!onboardings) return true;

  if (oid in onboardings) {
    return onboardings[oid]?.completed === false; // if this is true or 'force_skipped', then return not pending
  } else {
    return true;
  }
};
