import React, { createContext, useMemo, useReducer } from 'react';

import { AI_INITIAL_PLANS, INITIAL_PLANS } from '@constants/pricing';
import {
  AUTH_COOKIE,
  LOCAL_STORAGE,
} from '@invideoio/web-shared/utils/utils.constant';
import { AUTH_TAB } from '@invideoio/web-shared/components/Auth/constants';
import { FeatureConfig } from '@utils/experiments/types';
import { localStorageWithExpiry } from '@utils/localstorage.utils';
import Cookies from 'js-cookie';
import qs from 'query-string';
import { AiPlan, PricingDuration } from 'src/types/pricing.types';
import { ConfigType } from '../types';
import globalReducer from './reducer';
import { BaseAppType } from '@constants/common';

const initialRootState = {
  authModal: {
    state: false,
    tab: 'signup',
  },
  teamInviteModal: {
    state: false,
  },
  teamSelectionModal: {
    state: false,
  },
  teamJoinedModal: {
    state: false,
  },

  typeFormPersonalModal: {
    state: false,
  },
  personaModal: {
    state: false,
  },
  errorToast: {
    state: false,
    data: null,
  },
  redirectionLink: '',
  socialLinks: {
    twitterHandle: '',
    twitter: '',
    facebook: '',
    instagram: '',
    youtube: '',
    linkedin: '',
    pinterest: '',
    reviews: false,
  },
  planData: INITIAL_PLANS,
  aiPlanData: AI_INITIAL_PLANS,
  editorParams: {},
  baseAppType: BaseAppType.STUDIO,
  seats: { free: 1, plus: 1, max: 1, generative: 1 },
  aiCreditsCounter: { free: 0, plus: 1, max: 0, generative: 0 },
  aiPlanFeatures: {},
  addOnSubscriptionPlans: {},
};

export const StateContext = createContext<any>(null);
export const DispatchContext = createContext<any>(null);
export const RedirectContext = createContext<any>(null);

type ContextType = {
  initialState?: {
    os: string;
    platform: string;
    browser: string;
    socialLinks: Record<string, any>;
    config: ConfigType;
    featureConfig: FeatureConfig;
  };
};

const GlobalContext = ({
  children,
  initialState,
}: React.PropsWithChildren<ContextType>) => {
  const [state, dispatch] = useReducer(globalReducer, {
    ...initialRootState,
    ...initialState,
  });

  const stateMemo = useMemo(() => state, [state]);
  const dispatchMemo = useMemo(() => dispatch, [dispatch]);

  const handleClick = (
    link: string | null = null,
    options: { tab?: string; ux_source?: string } = {},
  ) => {
    const { tab, ux_source } = options;
    if (link && isExternalUrl(link)) {
      window.location.href = link;
      return;
    }

    if (link?.includes('ai.invideo.io')) {
      // oa is used to decide whether to show openapp or not
      const oa = new URL(link).searchParams.get('oa');
      goToAiSignup({ ux_source, oa });
      return;
    }

    // path names starting with /s/... should not be redirected to studio
    if (link && new URL(link).pathname.startsWith('/s/')) {
      window.location.href = link;
      return;
    }

    const url = new URL(
      process.env.NEXT_PUBLIC_STUDIO_HOST || 'https://studio.invideo.io',
    );

    const searchString = window.location.search.substring(1);

    const btn_position = localStorage.getItem(LOCAL_STORAGE.BUTTON_POSITION);

    if (searchString) {
      const params = qs.parse(searchString) || {};
      Object.keys(params).forEach(key => {
        if (typeof params[key] === 'string') {
          url.searchParams.append(key, params[key] as string);
        }
      });
    }

    if (localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN)) {
      const paramObj = {
        token: localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN),
        token_expires: localStorage.getItem('authExpires'),
      };

      if (link) {
        let linkUrl = new URL(link, process.env.NEXT_PUBLIC_STUDIO_HOST);

        url.searchParams.append(
          'otherUrl',
          encodeURIComponent(linkUrl.pathname + linkUrl.search),
        );
      }
      url.searchParams.append(
        'token',
        window.btoa(JSON.stringify(paramObj)) || '',
      );

      url.pathname = '/redirect';

      localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);
      localStorage.removeItem('authExpires');

      Cookies.remove(AUTH_COOKIE.AUTH_TOKEN);

      window.open(url.toString(), '_self');
    } else {
      if (link) {
        url.searchParams.append('otherUrl', encodeURIComponent(link));
      }
      if (tab === AUTH_TAB.LOGIN) {
        url.pathname = '/login';
      } else if (tab === AUTH_TAB.SIGN_UP) {
        url.pathname = '/signup';
      }

      if (btn_position) {
        url.searchParams.append('btn_position', btn_position);
      }

      window.open(url.toString(), '_self');
    }
  };

  const goToAiSignup = (
    options: {
      page?: string;
      ux_source?: string;
      oa?: string | null;
    } = {},
  ) => {
    const { page, ux_source } = options;
    const qs = localStorageWithExpiry.get('qs');
    const domain_referrer = localStorageWithExpiry.get('domain_referrer');

    let baseURL = `${process.env.NEXT_PUBLIC_AI_HOST}`;

    if (page === 'login') {
      baseURL = baseURL + '/login';
    } else {
      baseURL = baseURL + '/signup';
    }

    if (qs) {
      baseURL = baseURL + `${qs}`;
    }
    const url = new URL(baseURL);

    if (domain_referrer) {
      url.searchParams.append('dr', domain_referrer);
    }

    if (ux_source) {
      url.searchParams.append('ux_source', ux_source);
    }

    if (options.oa) {
      url.searchParams.append('oa', options.oa);
    }

    window.location.href = url.toString();
  };

  const isExternalUrl = (link: string | null): boolean => {
    if (!link) return false;
    const url = new URL(link);
    return !url.origin.includes('invideo.io');
  };

  const handlePricingCardClick = (
    plan: AiPlan,
    isYearlyDuration: boolean,
    seats: any,
    plan1?: string,
    seats1?: number,
  ) => {
    const duration = isYearlyDuration
      ? PricingDuration.YEARLY
      : PricingDuration.MONTHLY;

    const planId = plan.planPrice[duration].planId;

    const aiCoupon = localStorageWithExpiry.get('aiCoupon');

    const qs = localStorageWithExpiry.get('qs');
    const domain_referrer = localStorageWithExpiry.get('domain_referrer');

    seats = seats[plan.key] || 1;

    let baseURL = `${process.env.NEXT_PUBLIC_AI_HOST}/signup`;

    if (qs) {
      baseURL = baseURL + `${qs}`;
    }

    const url = new URL(baseURL);

    if (plan.key !== 'free') {
      let checkoutUrl = `/checkout?plan=${planId}&seats=${seats}`;

      if (typeof aiCoupon === 'string' && aiCoupon !== '') {
        checkoutUrl = checkoutUrl + `&coupon=${aiCoupon}`;
      }

      if (plan1 && seats1) {
        checkoutUrl = checkoutUrl + `&plan1=${plan1}&seats1=${seats1}`;
      }

      url.searchParams.append('nextURL', checkoutUrl);
    }

    if (domain_referrer) {
      url.searchParams.append('dr', domain_referrer);
    }

    window.open(url, '_self');
  };

  return (
    <StateContext.Provider value={stateMemo}>
      <DispatchContext.Provider value={dispatchMemo}>
        <RedirectContext.Provider
          value={{ handleClick, handlePricingCardClick, goToAiSignup }}
        >
          {children}
        </RedirectContext.Provider>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export default GlobalContext;
