type ActionWithPayload = {
  type: string;
  payload: any;
};

function globalReducer(state: any, action: ActionWithPayload) {
  switch (action.type) {
    case 'OPEN_AUTH_MODAL': {
      return {
        ...state,
        authModal: {
          state: true,
          ...action.payload,
        },
      };
    }
    case 'CLOSE_AUTH_MODAL': {
      return {
        ...state,
        authModal: {
          state: false,
          ...action.payload,
        },
      };
    }
    case 'OPEN_TEAM_INVITE_MODAL': {
      return {
        ...state,
        teamInviteModal: {
          state: true,
        },
      };
    }
    case 'CLOSE_TEAM_INVITE_MODAL': {
      return {
        ...state,
        teamInviteModal: {
          state: false,
        },
      };
    }

    case 'OPEN_TEAM_SELECTION_MODAL': {
      return {
        ...state,
        teamSelectionModal: { state: true },
      };
    }
    case 'CLOSE_TEAM_SELECTION_MODAL': {
      return {
        ...state,
        teamSelectionModal: { state: false },
      };
    }
    case 'OPEN_TEAM_JOINED_MODAL': {
      return {
        ...state,
        teamJoinedModal: { state: true },
      };
    }
    case 'CLOSE_TEAM_JOINED_MODAL': {
      return {
        ...state,
        teamJoinedModal: { state: false },
      };
    }
    case 'OPEN_TYPEFORM_PERSONA_MODAL': {
      return {
        ...state,
        typeFormPersonalModal: { state: true },
      };
    }

    case 'OPEN_PERSONA_MODAL': {
      return {
        ...state,
        personaModal: { state: true },
      };
    }

    case 'CLOSE_PERSONA_MODAL': {
      return {
        ...state,
        personaModal: { state: false },
      };
    }

    case 'OPEN_ERROR_TOAST': {
      return {
        ...state,
        errorToast: { state: true, data: action.payload },
      };
    }
    case 'CLOSE_ERROR_TOAST': {
      return {
        ...state,
        errorToast: { state: false, data: null },
      };
    }
    case 'SET_REDIRECTION_LINK': {
      return {
        ...state,
        redirectionLink: action.payload,
      };
    }
    case 'SET_SOCIAL_LINKS': {
      return {
        ...state,
        socialLinks: { ...action.payload },
      };
    }
    case 'SET_PLAN_DATA': {
      return {
        ...state,
        planData: action.payload,
      };
    }
    case 'SET_SEATS': {
      return {
        ...state,
        seats: { ...state.seats, ...action.payload },
      };
    }
    case 'SET_AI_CREDITS_COUNTER': {
      return {
        ...state,
        aiCreditsCounter: { ...state.aiCreditsCounter, ...action.payload },
      };
    }
    case 'SET_AI_PLAN_DATA': {
      return {
        ...state,
        aiPlanData: action.payload,
      };
    }
    case 'SET_AI_PLAN_FEATURES': {
      return {
        ...state,
        aiPlanFeatures: action.payload,
      };
    }
    case 'SET_ADD_ON_SUBSCRIPTION_PLANS': {
      return {
        ...state,
        addOnSubscriptionPlans: action.payload,
      };
    }
    case 'SET_EDITOR_PARAMS': {
      return {
        ...state,
        editorParams: action.payload,
      };
    }
    case 'BASE_APP_TYPE_LOADED': {
      return {
        ...state,
        baseAppType: action.payload,
      };
    }
    default: {
      throw new Error(`Unknown Action Type: ${action.type}`);
    }
  }
}

export default globalReducer;
