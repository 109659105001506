export enum MakePageType {
  Template = 'Template',
  NonTemplate = 'Non_Template',
}

export enum DimensionType {
  _1_1 = '1:1',
  _9_16 = '9:16',
  _16_9 = '16:9',
}

export enum CMSDimensionType {
  _1_1 = '_1_1',
  _9_16 = '_9_16',
  _16_9 = '_16_9',
}

export enum HeadingStyleType {
  Plain = 'Plain',
  Highlighted = 'Highlighted',
}

export const TYPEFORM_IDS = {
  WORK_INDUSTRY_ID: 'airgAP1av0Rq',
  USER_INDUSTRY_ID: '0YKwKP2m6cKC',
};

export enum BaseAppType {
  AI = 'AI',
  STUDIO = 'STUDIO',
}
