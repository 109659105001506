import { NextPageContext } from 'next';
import { BrowserType } from '@invideoio/web-shared/constants/common';
import { IBrowser, IResult } from '@invideoio/web-shared/Modules/IVUAParser';
/**
 CloudFront Device Type Headers
 CloudFront-Is-Tablet-Viewer
 CloudFront-Is-Android-Viewer
 CloudFront-Is-IOS-Viewer
 CloudFront-Is-Desktop-Viewer
 CloudFront-Is-Mobile-Viewer
 */

const getBrowser = (browser: IBrowser): BrowserType => {
  const sanitizedName = browser.name?.toLowerCase()?.trim() ?? '';
  if (sanitizedName === 'safari') return BrowserType.Safari;
  if (sanitizedName === 'chrome') return BrowserType.Chrome;
  return BrowserType.Others;
};

type DeviceDetectType = {
  os: string;
  platform: string;
  browser: BrowserType;
  userAgent: string;
  isGoogleBot: boolean;
};

const deviceDetect = async (
  ctx: NextPageContext,
): Promise<DeviceDetectType> => {
  const UAParser = (await import('@invideoio/web-shared/Modules/IVUAParser'))
    .default;

  const { req } = ctx || {};
  const { headers } = req || {};
  let os = '';
  let platform = 'desktop';
  let browser: BrowserType = BrowserType.Chrome;

  let isGoogleBot = false;
  const { 'user-agent': serverUserAgent = '' } = headers || {};
  const userAgent = req ? serverUserAgent : navigator.userAgent;

  if (
    userAgent?.includes('Googlebot') ||
    userAgent?.includes('Chrome-Lighthouse')
  ) {
    isGoogleBot = true;
  }

  if (
    process.env.NEXT_PUBLIC_ENV === 'production' ||
    process.env.NEXT_PUBLIC_ENV === 'staging'
  ) {
    const {
      'cloudfront-is-tablet-viewer': tablet,
      'cloudfront-is-mobile-viewer': mobile,
      'cloudfront-is-android-viewer': android,
      'cloudfront-is-ios-viewer': iOS,
    } = headers || {};

    if (tablet === 'true') {
      platform = 'tablet';
    } else if (mobile === 'true') {
      platform = 'mobile';
    }

    if (android === 'true') {
      os = 'Android';
    } else if (iOS === 'true') {
      os = 'iOS';
    }
  } else {
    const parser = new UAParser(userAgent);
    const {
      device,
      os: userAgentOS,
      browser: userAgentBrowser,
    }: IResult = parser.getResult();

    platform = device.type || 'desktop';
    os = userAgentOS.name || '';
    browser = getBrowser(userAgentBrowser);
  }

  return { os, platform, browser, userAgent, isGoogleBot };
};

export default deviceDetect;
