export type PaymentPlan =
  | 'BUSINESS_WATERMARK'
  | 'FILMR_PRO'
  | 'FREE'
  | 'PROFESSIONAL'
  | 'BUSINESS_TRIAL'
  | 'BUSINESS'
  | 'UNLIMITED'
  | 'PREMIUM';

interface PlanPrice {
  monthly: {
    currency: string;
    price: number;
    subText: string;
    starText?: string;
  };
  annually: {
    currency: string;
    price: number;
    subText: string;
    starText?: string;
  };
}
interface CTA {
  monthly: {
    text: string;
    url: string;
    action?: string;
    backgroundColorClass: string;
    textColorClass: string;
  };
  annually: {
    text: string;
    url: string;
    action?: string;
    backgroundColorClass: string;
    textColorClass: string;
  };
}

interface Feature {
  highlightedText: string;
  plainText: string;
}
interface Redirection {
  url: string;
  type: string;
  modal?: boolean;
}
interface AppPromo {
  firstLine: string;
  secondLine: string;
  CTAText: string;
  backgroundGradientClass: string;
  appImage: string;
}

export interface PlanAction {
  actionText: string;
  action: string;
  actionUrl: string;
  displayText: string;
}

export interface Stripe {
  gatewayPlanId: string;
  inrPlanId: string;
  usdPlanId: string;
  productId: string;
}

export interface Recurly {
  gatewayPlanId: string;
}

export interface PlanAction {
  actionText: string;
  action: string;
  actionUrl: string;
  displayText: string;
}

export interface GatewayData {
  gatewayPlanId?: string;
  inrPlanId?: string;
  usdPlanId?: string;
  productId?: string;
}

export interface DurationObject {
  durationInDays: number;
  durationDisplay: string;
  planAction?: PlanAction;
  basePrice: number;
  displayPrice?: number | string;
  displayBasePrice?: number | string;
  sellingPrice: number;
  stripe: GatewayData;
  recurly: GatewayData;
  paddle?: GatewayData;
  product_code: string;
  monthly_price: number;
  monthlyPrice?: number;
  monthly_price_display: number;
}

export interface Plan {
  name: string;
  description: string;
  bannerText?: string;
  planPrice?: PlanPrice;
  CTA: CTA;
  feature: Feature[];
  features: string[];
  key_features: string[];
  currency_symbol: string;
  label: string;
  rank: number;
  tagline: string;
  featuresCheckImage: string;
  redirection: Redirection;
  appPromo?: AppPromo;
  durations: DurationObject[];
  id: number;
}

export type PricingPlans = Plan[];

export interface PlanResponse {
  id: number;
  rank: number;
  name: string;
  label: string;
  description: string;
  tagline: string;
  currency_symbol: string;
  features: Array<string>;
  key_features?: Array<string>;
  durations: Array<DurationObject>;
  color?: string;
  appsumo?: boolean;
  gateway?: GatewayData;
  duration?: Array<DurationObject>;
}

interface AiPlanDescription {
  desktop: string;
  mobile: string;
}

interface AiPlanPrice {
  monthly: {
    currency: string;
    price: number;
    planId: string;
    subText?: string;
  };
  yearly: {
    currency: string;
    price: number;
    planId: string;
    subText?: string;
  };
}

interface AiCTA {
  text: string;
  url: string;
  action?: string;
  backgroundColorClass: string;
  textColorClass: string;
}

export interface AiFeature {
  icon: string;
  text: string;
  name: string;
  quota: number;
  help?: string;
  style?: string;
}

export interface AiPlan {
  key: string;
  name: string;
  description: AiPlanDescription;
  bannerText?: string;
  planPrice: AiPlanPrice;
  CTA: AiCTA;
  key_features: AiFeature[];
  currency_symbol: string;
  redirection: Redirection;
  sequence: number;
}

export enum PricingDuration {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export type PricingProps = {
  currency: string;
  price: number;
  subText: string;
  starText?: string;
};

export type PricingRedirectionProps = {
  type: string;
  url: string;
  modal?: boolean;
};

export type PricingPlanTopProps = {
  planName: string;
  planKey: string;
  planPrice: AiPlanPrice;
  bannerText?: string;
  isYearlyDuration: boolean;
  CTA: {
    text: string;
    backgroundColorClass: string;
  };
  theme?: 'dark' | 'light';
  onClick: () => void;
  t?: Function;
  disabled?: boolean;
  classNames?: string;
  renderPropsTop?: () => JSX.Element;
};

export type PricingPlanBottomProps = {
  planKey: string;
  features: AiFeature[];
  goToPlanDetails?: Function;
  CTA: {
    text: string;
    backgroundColorClass: string;
  };
  theme?: 'dark' | 'light';
  onClick: () => void;
};

export type PlanProps = PricingPlanTopProps & PricingPlanBottomProps;
